<template>
  <div>
    <b-card>
      <b-row v-show="paymentStatus === 'fail'">
        <b-col cols="12" md="12" lg="12">
          <b-alert variant="danger" show>
            <div class="alert-body">
              <span>
                <strong>{{ $t('Başarısız.') }}!</strong> 
                {{ $t('Ödeme işleminiz başarısız oldu. lütfen tekrar deneyin.') }}.
              </span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <div v-if="paymentStatus !== 'fail'">
        <b-row v-if="paymentData === null">
          <b-col cols="12" md="12" lg="12" v-if="paymentData === null">
            <b-alert variant="warning" show>
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Yükleniyor') }}!</strong>
                  {{ $t('Ödeme işleminiz alındı. Lütfen bekleyin...') }}
                  <b-spinner small />
                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row v-if="paymentData">
          <!-- payment success -->
          <b-col cols="12" md="12" lg="12" v-if="paymentData.isSuccessOdeme">
            <b-alert variant="success" show>
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Başarılı') }}!</strong> 
                  {{ $t('Ödeme işleminiz başarılı bir şekilde alındı.') }}.
                </span>
              </div>
            </b-alert>
          </b-col>

          <!-- payment fail -->
          <b-col cols="12" md="6" lg="6" v-if="!paymentData.isSuccessOdeme">
            <b-alert variant="danger" show>
              <div class="alert-body">
                <span>
                  <strong>{{ $t('Ödeme Hatası:') }}!</strong> 
                  {{ paymentData.responseMessage }}.
                </span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import AlertService from '@/common/alert.service'

export default {
  data() {
    return {
      paymentStatus: null,
      paymentData: null,
    }
  },
  methods: {
    controlPaymentStatus() {
      this.$store.dispatch('paymentCustomer/CheckPaymentStatus', this.paymentStatus)
        .then(response => {
          if (response.statusCode === 200) {
            if (response.result.isCompletOdme) {
              this.paymentData = response.result;
            } else {
              this.controlPaymentStatus();
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
  },
  mounted() {
    this.paymentStatus = this.$router.currentRoute.params.status;
    if (this.paymentStatus !== 'fail') {
      this.controlPaymentStatus();
    }
  },
}
</script>

<style>

</style>
